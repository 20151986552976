import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';
import { round } from 'lodash';

const NaturalSupportsTable = props => {
    const { data, nattySups } = props;

    const getData = d => {
        const rows = [];
        for (const n of nattySups) {
            const row = { tm: n.label };
            for (const t of ['Present', 'Team']) {
                const v = d.flows?.meeting[`${n.name}${t}`];
                row[t.toLowerCase()] = v || 0;
            }
            row.perc = (row.present / row.team) * 100 || 0;
            rows.push(row);
        }
        return rows;
    };

    const cols = [
        {
            name: 'tm',
            label: 'Team Member',
        },
        {
            name: 'team',
            label: 'Number on Wraparound Team',
        },
        {
            name: 'present',
            label: 'Number Present at Meeting',
        },
        {
            name: 'perc',
            label: 'Percent Attendance',
            render: value => `${round(value, 2)}%`,
        },
    ];

    const tableData = getData(data);

    return <Table data={tableData} rowKey="tm" columns={cols} showPerPageOptions={false} isCsvDownload />;
};

NaturalSupportsTable.propTypes = {
    data: PropTypes.object,
    nattySups: PropTypes.array,
};

export default NaturalSupportsTable;
