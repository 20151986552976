import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Collapsable from 'js/vendor/reports/components/Collapsable';
import DataCollectionInfoTable from './tables/DataCollectionInfoTable';
import RespondentDataCollectionInfo from './tables/RespondentDataCollectionInfo';
import OtherResponseTable from './tables/OtherResponseTable';
import OptOutReasonTable from './tables/OptOutReasonTable';
import DuplicateInfoTable from './tables/DuplicateInfoTable';
import ExclusionInfoTable from './tables/ExclusionInfoTable';

import { taskMasterClient } from 'cccisd-apollo';
import { connect } from 'react-redux';
import { setWidgetLoaded } from 'js/reducers/report.js';
import { loadGroups } from 'js/reducers/sites.js';

import cycleInfoQuery from './graphql/cycleInfo.graphql';
import duplicateInfoQuery from './graphql/duplicateInfo.graphql';
import exclusionInfoQuery from './graphql/exclusionInfo.graphql';
import responseInfoQuery from './graphql/responseInfo.graphql';
import otherReasonsQuery from './graphql/otherReasons.graphql';

import { showTable, renderSubtitle, getGroupLevelMap } from 'js/vendor/reports/helpers.js';

function SampleResponseSummary(props) {
    const [cycleInfo, setCycleInfo] = useState(null);
    const [duplicateInfo, setDuplicateInfo] = useState(null);
    const [exclusionInfo, setExclusionInfo] = useState(null);
    const [responseInfo, setResponseInfo] = useState(null);
    const [otherReasons, setOtherReasons] = useState(null);

    const [loading, setLoading] = useState(true);
    const [groupLevelMap, setGroupLevelMap] = useState(null);

    const groupLevel = props.groupLevel || 'group';
    useEffect(() => {
        async () => {
            if (props.groups === []) {
                await props.loadGroups();
            }
        };
    }, []);

    useEffect(() => {
        if (props.groups.length > 0) {
            setLoading(true);
            setGroupLevelMap(getGroupLevelMap(props.groups, groupLevel, props.filters.siteSelectorField));
            getData();
        }
    }, [props.filters.evalCycleSelectorField, props.filters.siteSelectorField, props.youthPawnIds]);

    const getData = async () => {
        const queries = [
            { setter: setCycleInfo, query: cycleInfoQuery },
            { setter: setDuplicateInfo, query: duplicateInfoQuery },
            { setter: setExclusionInfo, query: exclusionInfoQuery },
            { setter: setOtherReasons, query: otherReasonsQuery },
            { setter: setResponseInfo, query: responseInfoQuery },
        ].map(q => ({
            setter: q.setter,
            query: {
                query: q.query,
                variables: {
                    deploymentIds: props.filters.evalCycleSelectorField || [],
                    pawnIds: props.youthPawnIds || [],
                },
                fetchPolicy: 'network-only',
                errorPolicy: 'all',
            },
        }));
        await Promise.all(
            queries.map(query =>
                taskMasterClient.query({ ...query.query, cancelTag: 'report' }).then(response => {
                    query.setter(response.data);
                    return false;
                })
            )
        );
        setLoading(false);

        props.setWidgetLoaded(props.section.name + 'Section');
    };

    return (
        <>
            {renderSubtitle('Evaluation Cycles and Response Rates')}
            {showTable(<DataCollectionInfoTable data={cycleInfo} groups={props.groups} />, loading)}
            {renderSubtitle(`Data Collection Information for TOM 2.0 Form`)}
            {showTable(
                <RespondentDataCollectionInfo
                    data={loading ? [] : responseInfo.flows.Youth}
                    groups={props.groups}
                    groupLevel={groupLevel}
                    groupLevelMap={groupLevelMap}
                />,
                loading
            )}
            {renderSubtitle(`Opt-out Reason for TOM 2.0 Form`)}
            {showTable(
                <OptOutReasonTable
                    data={loading ? [] : otherReasons.flows.Youth}
                    groups={props.groups}
                    groupLevel={groupLevel}
                    groupLevelMap={groupLevelMap}
                />,
                loading
            )}
            {showTable(
                <Collapsable
                    title={`"Other" responses`}
                    collapsed
                    content={<OtherResponseTable data={loading ? [] : otherReasons.flows.Youth} />}
                />,
                loading
            )}
            {renderSubtitle('Duplicate Information')}
            {showTable(
                <DuplicateInfoTable
                    data={loading ? [] : duplicateInfo.roles.youthList}
                    groupLevel={groupLevel}
                    groupLevelMap={groupLevelMap}
                />,
                loading
            )}
            {renderSubtitle('Exclusion Information')}
            {showTable(<ExclusionInfoTable data={loading ? [] : exclusionInfo.flows.Youth} />, loading)}
        </>
    );
}

SampleResponseSummary.propTypes = {
    filters: PropTypes.object,
    settings: PropTypes.object,
    section: PropTypes.object,
    groupLevel: PropTypes.string,
    // redux
    youthPawnIds: PropTypes.array,
    setWidgetLoaded: PropTypes.func,
    loadGroups: PropTypes.func,
    groups: PropTypes.array,
};

const mapStateToProps = state => ({
    groups: state.app.sites.groups,
    youthPawnIds: state.app.report.youthPawnIds,
});

export default connect(mapStateToProps, { setWidgetLoaded, loadGroups })(SampleResponseSummary);
