import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-table';

const DataCollectionInfoTable = props => {
    const { data } = props;
    const cols = [
        {
            name: 'careCoord',
            label: 'Care Coordinator',
            sort: true,
            filter: true,
        },
        { name: 'notStarted', sort: true, label: 'Number of Forms Not Started' },
        { name: 'incomplete', sort: true, label: 'Number of Forms Incomplete' },
        { name: 'numCompleted', sort: true, label: 'Number of Forms Completed' },
        { name: 'optedOut', sort: true, label: 'Number of Forms Opt-out' },
    ];

    const getData = () => {
        let rows = [];
        for (var cc of Object.keys(data)) {
            if (cc !== 'otherReasons') {
                let row = { careCoord: cc.split(', ')[0] };
                for (var col of cols) {
                    if (col.name !== 'careCoord') {
                        row[col.name] = data[cc][col.name] || 0;
                    }
                }
                rows.push(row);
            }
        }
        return rows;
    };

    const getTotalRow = d => {
        let foot = {
            careCoord: 'Total:',
            notStarted: 0,
            incomplete: 0,
            numCompleted: 0,
            optedOut: 0,
        };
        for (var row of d) {
            foot.notStarted += row.notStarted;
            foot.incomplete += row.incomplete;
            foot.numCompleted += row.numCompleted;
            foot.optedOut += row.optedOut;
        }
        return foot;
    };

    const tableData = getData();
    const footer = getTotalRow(tableData);
    return (
        <Table
            data={tableData}
            rowKey="careCoord"
            orderBy="careCoord"
            columns={cols}
            showPerPageOptions={false}
            footer={footer}
            isCsvDownload
        />
    );
};

DataCollectionInfoTable.propTypes = {
    data: PropTypes.object,
};

export default DataCollectionInfoTable;
